import { actionTypes, mutationTypes, getterTypes, namespace } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { TroubledBorrowerController } from "@/api/loan/troubledBorrower";
import MainInfoState from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoState";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import storeManager from "@/store/manager";
import TroubledBorrowerState from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerState";
import { MainInfoModeType } from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types/mainInfoModeType";
import TroubledBorrowerVersion from "@/store/loan/modules/troubledBorrower/types/troubledBorrowerVersion";
import ApiTroubledBorrowerVersion from "@/api/loan/types/troubledBorrower/apiTroubledBorrowerVersion";
import mapper from "@/store/loan/modules/troubledBorrower/mapper";
import { cloneDeep } from "lodash";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";

const abortService = new AbortService();
const troubledBorrowerController = new TroubledBorrowerController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();
const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableVersion"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}

	build() {
		return new MainInfoState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<MainInfoState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.version]: (state, getters, rootState) => {
		return resolveNestedState<TroubledBorrowerState>(rootState, storeManager.loan.troubledBorrower.namespace).version;
	}
};

const actions = <ActionTree<MainInfoState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit }) {
		await dispatch(actionTypes.initializeBase);

		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.save]({ commit, state }) {
		commit(mutationTypes.SET_IS_FORM_SAVING, true);

		try {
			await troubledBorrowerController.updateVersion(state.editableVersion.versionHeader.versionId,
				mapper.map(state.editableVersion, TroubledBorrowerVersion, ApiTroubledBorrowerVersion));
			
			const version = await troubledBorrowerController.getVersion(state.editableVersion.versionHeader.versionId);

			commit(mutationTypes.VERSION_UPDATED_EVENT, mapper.map(version, ApiTroubledBorrowerVersion, TroubledBorrowerVersion));

			commit(mutationTypes.SET_MODE, MainInfoModeType.READ);
			alertService.addInfo(AlertKeys.SUCCESS_UPDATED_INFO);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_SAVING, false);
		}
	}
};

const mutations = <MutationTree<MainInfoState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_MODE](state, value) {
		state.mode = value;
	},
	[mutationTypes.SET_EDITABLE_VERSION](state, value) {
		state.editableVersion = cloneDeep(value);
	},
	[mutationTypes.SET_MAIN_DEBT](state, value) {
		state.editableVersion.mainDebt = value;
	},
	[mutationTypes.SET_INTEREST](state, value) {
		state.editableVersion.interest = value;
	},
	[mutationTypes.SET_PENALTY](state, value) {
		state.editableVersion.penalty = value;
	},
	[mutationTypes.SET_REFUND_STATUS_TYPE_ID](state, value) {
		state.editableVersion.refund.refundStatusTypeId = value;
	},
	[mutationTypes.SET_REFUND_WITHOUT_ACCEPTANCE](state, value) {
		state.editableVersion.refund.withoutAcceptance = value;
	},
	[mutationTypes.SET_REFUND_BANK_GUARANTEE](state, value) {
		state.editableVersion.refund.bankGuarantee = value;
	},
	[mutationTypes.SET_REFUND_PENALTY](state, value) {
		state.editableVersion.refund.penalty = value;
	},
	[mutationTypes.SET_KEY_DATES_RECALL_REASON](state, value) {
		state.editableVersion.keyDates.recallReason = value;
	},
	[mutationTypes.SET_KEY_DATES_RECALL_DATE](state, value) {
		state.editableVersion.keyDates.recallDate = value;
	},
	[mutationTypes.SET_KEY_DATES_REQUIREMENTS](state, value) {
		state.editableVersion.keyDates.requirements = value;
	},
	[mutationTypes.SET_KEY_DATES_DECLARATION](state, value) {
		state.editableVersion.keyDates.declaration = value;
	},
	[mutationTypes.SET_KEY_DATES_SETTLEMENT](state, value) {
		state.editableVersion.keyDates.settlement = value;
	},
	[mutationTypes.SET_LEGAL_DEPARTMENT_USER_ID](state, value) {
		state.editableVersion.responsibleUsersInfo.legalDepartmentUserId = value;
	},
	[mutationTypes.SET_PROJECT_MANAGER_USER_ID](state, value) {
		state.editableVersion.responsibleUsersInfo.projectManagerUserId = value;
	},
	[mutationTypes.VERSION_UPDATED_EVENT]() {
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch, getters } = store;

	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(storeManager.loan.troubledBorrower.mainInfo.namespace, mutationTypes.SET_MODE):
			{
				const version = getters[resolveGetter(storeManager.loan.troubledBorrower.mainInfo.namespace, getterTypes.version)];

				if(payload === MainInfoModeType.EDIT) {
					commit(resolveMutation(storeManager.loan.troubledBorrower.mainInfo.namespace, mutationTypes.SET_EDITABLE_VERSION),
						version);

					commit(resolveMutation(storeManager.loan.troubledBorrower.mainInfo.namespace, mutationTypes.SET_STATE_SNAPSHOT),
						stateSnapshotKeys.LAST_SAVED);
				}

				break;
			}

			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const mainInfoModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default mainInfoModule;
