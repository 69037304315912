export default class RefundSource {
	constructor(
		public id?: string,
		public refundSourceTypeId?: string,
		public paymentDate: number = 0,
		public paymentSum: number = 0
	)
	{
	}
}
