import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiAgreement from "@/api/loan/types/loanSchedule/apiAgreement";
import ApiVersionHeader from "@/api/loan/types/loanSchedule/apiVersionHeader";
import ApiVersion from "@/api/loan/types/loanSchedule/apiVersion";
import ApiScheduleItem from "@/api/loan/types/loanSchedule/apiScheduleItem";
import ApiTranche from "@/api/loan/types/loanSchedule/apiTranche";
import ApiPayment from "@/api/loan/types/loanSchedule/apiPayment";
import ApiOverdue from "@/api/loan/types/loanSchedule/apiOverdue";

export class LoanScheduleController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getAgreement = async (projectId: string): Promise<ApiAgreement> => {
		let data = await this.client.get(urls.loan.loanSchedule.agreement.get.replace(urlTemplateParts.id, projectId));
		return plainToInstance(ApiAgreement, data);
	};

	getVersionHeaders = async (projectId: string): Promise<ApiVersionHeader[]> => {
		let data = await this.client.get<object[]>(urls.loan.loanSchedule.versions.getVersionHeaders.replace(urlTemplateParts.id,
			projectId));
		return plainToInstance(ApiVersionHeader, data);
	};

	getVersion = async (versionId: string): Promise<ApiVersion> => {
		let data = await this.client.get(urls.loan.loanSchedule.versions.getVersion.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiVersion, data);
	};

	createDraft = async (versionId: string, draft: ApiVersion): Promise<ApiVersion> => {
		let data = await this.client.post(urls.loan.loanSchedule.versions.createDraft.replace(urlTemplateParts.id, versionId), draft);
		return plainToInstance(ApiVersion, data);
	};

	updateDraft = async (versionId: string, draft: ApiVersion): Promise<ApiVersion> => {
		let data = await this.client.put(urls.loan.loanSchedule.versions.updateDraft.replace(urlTemplateParts.id, versionId), draft);
		return plainToInstance(ApiVersion, data);
	};

	deleteDraft = async (versionId: string): Promise<void> => {
		await this.client.delete(urls.loan.loanSchedule.versions.deleteDraft.replace(urlTemplateParts.id, versionId));
	};

	createFirstDraft = async (version: ApiVersion): Promise<ApiVersion> => {
		let data = await this.client.post(urls.loan.loanSchedule.versions.createFirstDraft, version);
		return plainToInstance(ApiVersion, data);
	};

	activateVersion = async (versionId: string): Promise<ApiVersion> => {
		let data = await this.client.put(urls.loan.loanSchedule.versions.activateVersion.replace(urlTemplateParts.id, versionId), {});
		return plainToInstance(ApiVersion, data);
	};

	getScheduleItems = async (versionId: string): Promise<ApiScheduleItem[]> => {
		let data = await this.client.get<object[]>(urls.loan.loanSchedule.schedule.get.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiScheduleItem, data);
	};

	createScheduleItem = async (versionId: string, item: ApiScheduleItem): Promise<ApiScheduleItem> => {
		let data = await this.client.post(urls.loan.loanSchedule.schedule.create.replace(urlTemplateParts.id, versionId), item);
		return plainToInstance(ApiScheduleItem, data);
	};

	updateScheduleItems = async (versionId: string, items: ApiScheduleItem[]): Promise<void> => {
		return await this.client.put(urls.loan.loanSchedule.schedule.update.replace(urlTemplateParts.id, versionId), items);
	};

	deleteScheduleItem = async (versionId: string, itemId: string): Promise<void> => {
		return await this.client.delete(urls.loan.loanSchedule.schedule.delete
											.replace(urlTemplateParts.id, versionId)
											.replace(urlTemplateParts.secondId, itemId));
	};

	getPayments = async (projectId: string): Promise<ApiPayment[]> => {
		let data = await this.client.get<object[]>(urls.loan.loanSchedule.payments.get.replace(urlTemplateParts.id, projectId));
		return plainToInstance(ApiPayment, data);
	};

	getTranches = async (versionId: string): Promise<ApiTranche[]> => {
		let data = await this.client.get<object[]>(urls.loan.loanSchedule.tranches.get.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiTranche, data);
	};

	createTranche = async (versionId: string, item: ApiTranche): Promise<ApiTranche> => {
		let data = await this.client.post(urls.loan.loanSchedule.tranches.create.replace(urlTemplateParts.id, versionId), item);
		return plainToInstance(ApiTranche, data);
	};

	updateTranches = async (versionId: string, items: ApiTranche[]): Promise<void> => {
		return await this.client.put(urls.loan.loanSchedule.tranches.update.replace(urlTemplateParts.id, versionId), items);
	};

	deleteTranche = async (versionId: string, itemId: string): Promise<void> => {
		return await this.client.delete(urls.loan.loanSchedule.tranches.delete
											.replace(urlTemplateParts.id, versionId)
											.replace(urlTemplateParts.secondId, itemId));
	};

	getOverdues = async (versionId: string): Promise<ApiOverdue[]> => {
		let data = await this.client.get<object[]>(urls.loan.loanSchedule.overdues.get.replace(urlTemplateParts.id, versionId));
		return plainToInstance(ApiOverdue, data);
	};

	createOverdue = async (versionId: string, item: ApiOverdue): Promise<ApiOverdue> => {
		let data = await this.client.post(urls.loan.loanSchedule.overdues.create.replace(urlTemplateParts.id, versionId), item);
		return plainToInstance(ApiOverdue, data);
	};

	updateOverdues = async (versionId: string, items: ApiOverdue[]): Promise<void> => {
		return await this.client.put(urls.loan.loanSchedule.overdues.update.replace(urlTemplateParts.id, versionId), items);
	};

	deleteOverdue = async (versionId: string, itemId: string): Promise<void> => {
		return await this.client.delete(urls.loan.loanSchedule.overdues.delete
											.replace(urlTemplateParts.id, versionId)
											.replace(urlTemplateParts.secondId, itemId));
	};
}
