<template>
	<v-card elevation="0" class="loan-card px-4 pt-4 pb-7 fill-height">
		<div class="text-h6 blue--text text--darken-4 mb-1">
			{{ $t("details.titles.refund") }}
		</div>
		<v-row class="mx-0 mt-3">
			<v-col cols="7" class="pa-0 main-block-column main-block-column-left">
				<div>{{ $t("details.titles.factualRefundPercent") }}</div>
				<div>{{ $t("details.titles.factualRefundAbsolute") }}</div>
				<div class="pl-12">{{ $t("details.titles.refundWithoutAcceptance") }}</div>
				<div class="pl-12">{{ $t("details.titles.refundGuarantee") }}</div>
				<div class="pl-12">{{ $t("details.titles.refundCourt") }}</div>
				<div class="pl-12">{{ $t("details.titles.refundBankruptcy") }}</div>
				<div class="pl-12">{{ $t("details.titles.refundSettlement") }}</div>
				<div class="pl-12">{{ $t("details.titles.refundCession") }}</div>
				<div>{{ $t("details.titles.remainingRefund") }}</div>
			</v-col>
			<v-col cols="5" class="pa-0 main-block-column main-block-column-right">
				<div>
					<span v-if="version.mainDebt">{{ formatCurrency(version.refundsTotal.factualRefundPercent) }}%</span>
					<span v-else>%</span>
				</div>
				<div>{{ formatCurrency(version.refundsTotal.factualRefundAbsolute, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.withoutAcceptance, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.guarantee, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.court, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.bankruptcy, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.settlement, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.cession, 1) }}</div>
				<div>{{ formatCurrency(version.refundsTotal.refundLeftover, 1) }}</div>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { listMixin } from "@/mixins/listMixin";
import { formatDate } from "@/utils/dates";
import { dateFormat, dateTimeFormat } from "@/utils/formats";
import { formatCurrency } from "@/utils/formatting";
import FrpDateField from "Components/fields/FrpDateField";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpIcon from "Components/icon/FrpIcon";
import FrpDialog from "Components/dialogs/FrpDialog";
import FrpTextBodyTwo from "Components/typography/FrpTextBodyTwo";
import {
	getterTypes,
	mutationTypes
} from "@/store/loan/modules/troubledBorrower/modules/mainInfo/types";
import storeManager from "Store/manager";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import authorizationMixin from "Mixins/authorizationMixin";
import formMixin from "Mixins/formMixin";

const namespace = storeManager.loan.troubledBorrower.mainInfo.namespace;
const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const {
	mapState: troubledBorrowerMapState,
	mapGetters: troubledBorrowerMapGetters,
	mapActions: troubledBorrowerMapActions
} = createNamespacedHelpers(storeManager.loan.troubledBorrower.namespace);

export default {
	mixins: [colorsMixin, authorizationMixin, formMixin, listMixin],
	data() {
		return {
			namespace,
			formatCurrency,
			dateFormat,
			dateTimeFormat,
			formatDate
		};
	},
	computed: {
		...troubledBorrowerMapState({
			refundStatusTypes: state => state.refundStatusTypes,
			refundStatusTypeHistory: state => state.refundStatusTypeHistory
		}),
		...mapGetters({
			version: getterTypes.version
		})
	},
	components: {
		FrpDateField,
		FrpAutocomplete,
		FrpBtn,
		FrpIcon,
		FrpDialog,
		FrpTextBodyTwo
	}
};
</script>

<style scoped lang="scss">
.main-block-column {
  & > div {
	height: 48px;
	display: flex;
	align-items: center;
  }

  & > div:nth-child(even) {
	background-color: var(--v-primary-lighten4);
  }
}

.main-block-column-left {
  & > div {
	padding-left: 16px;
  }
}

.main-block-column-right {
  & > div {
	padding-right: 16px;
  }
}
</style>
