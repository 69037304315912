import urls, { urlTemplateParts } from "@/api/config/urls";
import { plainToInstance } from "class-transformer";
import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import ApiGetAgreementsAccruedInterestParameters from "@/api/loan/types/loanInterest/apiGetAgreementsAccruedInterestParameters";
import ApiOverdueInterest from "@/api/loan/types/loanInterest/apiOverdueInterest";
import ApiPenaltyInterest from "@/api/loan/types/loanInterest/apiPenaltyInterest";
import ApiAgreementsAccruedInterestItems from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterestItems";
import ApiAgreementsAccruedInterest from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterest";
import ApiAccruedInterest from "@/api/loan/types/loanInterest/apiAccruedInterest";
import ApiAccruedInterestItems from "@/api/loan/types/loanInterest/apiAccruedInterestItems";
import ApiGetAccruedInterestParameters from "@/api/loan/types/loanInterest/apiGetAccruedInterestParameters";
import ApiMassUpdateAccruedInterest from "@/api/loan/types/loanInterest/apiMassUpdateAccruedInterest";
import ApiExtraRateParameters from "@/api/loan/types/loanInterest/apiExtraRateParameters";
import ApiAccruedInterestAddItemsParameters from "@/api/loan/types/loanInterest/apiAccruedInterestAddItemsParameters";
import ApiAccrueInterestParameters from "@/api/loan/types/loanInterest/apiAccrueInterestParameters";
import { InterestActionType } from "@/store/loan/modules/agreementsAccruedInterest/types/interestActionType";
import ApiAgreementsAccruedInterestReport from "@/api/loan/types/loanInterest/apiAgreementsAccruedInterestReport";
import ApiUpcomingPaymentsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsItem";
import ApiUpcomingPaymentsInterestDetailsItem from "@/api/loan/types/loanInterest/apiUpcomingPaymentsInterestDetailsItem";
import { ApiAgreementsOverduePaymentsItem } from "@/api/loan/types/loanInterest/apiAgreementsOverduePaymentsItem";
import { ApiAgreementsAccruedPenaltiesItem } from "@/api/loan/types/loanInterest/apiAgreementsAccruedPenaltiesItem";
import { prepareUrl } from "@/utils/prepareUrlQuery";

export class LoanInterestController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getAgreementsAccruedInterest = async (parameters: ApiGetAgreementsAccruedInterestParameters): Promise<ApiAgreementsAccruedInterestItems> => {
		let data = await this.client.post<{
			items: ApiAgreementsAccruedInterest[],
			totalCount: number
		}>(urls.loan.loanInterest.agreementsAccruedInterest.get,
			parameters);
		return new ApiAgreementsAccruedInterestItems(plainToInstance(ApiAgreementsAccruedInterest, data.items), data.totalCount);
	};
	
	getAgreementsPenaltyInterest = async (): Promise<ApiAgreementsAccruedPenaltiesItem[]> => {
		let { items } = await this.client.get(urls.loan.loanInterest.agreementsPayments.getPenaltyInterest);
		return items;
	};
	
	getAgreementsPenaltyMainDebt = async (): Promise<ApiAgreementsAccruedPenaltiesItem[]> => {
		let { items } = await this.client.get(urls.loan.loanInterest.agreementsPayments.getPenaltyMainDebt);
		return items;
	};
	
	getAgreementsOverdueInterest = async (): Promise<ApiAgreementsOverduePaymentsItem[]> => {
		let { items } = await this.client.get(urls.loan.loanInterest.agreementsPayments.getOverdueInterest);
		return items;
	};
	
	getAgreementsOverdueMainDebt = async (): Promise<ApiAgreementsOverduePaymentsItem[]> => {
		let { items } = await this.client.get(urls.loan.loanInterest.agreementsPayments.getOverdueMainDebt);
		return items;
	};
	
	handleInterestAction = async (parameters: ApiAccrueInterestParameters, type: InterestActionType): Promise<Blob> => {
		const url = type === InterestActionType.ACCRUE
			? urls.loan.loanInterest.agreementsAccruedInterest.accrueInterest
			: urls.loan.loanInterest.agreementsAccruedInterest.accounting;
		
		return await this.client.post<Blob>(url, parameters, { responseType: "blob" }, true);
	};
	
	handleApproveAccounting = async (parameters: ApiAgreementsAccruedInterestReport): Promise<void> => {
		return await this.client.post(urls.loan.loanInterest.agreementsAccruedInterest.approveAccounting, parameters);
	};
	
	getOverdueInterest = async (projectId: string): Promise<ApiOverdueInterest[]> => {
		const data = await this.client.get<object[]>(urls.loan.loanInterest.overdueInterest.get.replace(urlTemplateParts.id, projectId));
		return plainToInstance(ApiOverdueInterest, data);
	};
	
	createOverdueInterestItem = async (projectId: string, item: ApiOverdueInterest): Promise<ApiOverdueInterest> => {
		const data = await this.client.post(urls.loan.loanInterest.overdueInterest.create.replace(urlTemplateParts.id, projectId), item);
		return plainToInstance(ApiOverdueInterest, data);
	};
	
	updateOverdueInterest = async (projectId: string, items: ApiOverdueInterest[]): Promise<void> => {
		return await this.client.put(urls.loan.loanInterest.overdueInterest.update.replace(urlTemplateParts.id, projectId), items);
	};
	
	deleteOverdueInterestItem = async (projectId: string, itemId: string): Promise<void> => {
		return await this.client.delete(urls.loan.loanInterest.overdueInterest.delete
											.replace(urlTemplateParts.id, projectId)
											.replace(urlTemplateParts.secondId, itemId));
	};
	
	getPenaltyInterest = async (projectId: string): Promise<ApiPenaltyInterest[]> => {
		const data = await this.client.get<object[]>(urls.loan.loanInterest.penaltyInterest.get.replace(urlTemplateParts.id, projectId));
		return plainToInstance(ApiPenaltyInterest, data);
	};
	
	createPenaltyInterestItem = async (projectId: string, item: ApiPenaltyInterest): Promise<ApiPenaltyInterest> => {
		const data = await this.client.post(urls.loan.loanInterest.penaltyInterest.create.replace(urlTemplateParts.id, projectId), item);
		return plainToInstance(ApiPenaltyInterest, data);
	};
	
	updatePenaltyInterest = async (projectId: string, items: ApiPenaltyInterest[]): Promise<void> => {
		return await this.client.put(urls.loan.loanInterest.penaltyInterest.update.replace(urlTemplateParts.id, projectId), items);
	};
	
	deletePenaltyInterestItem = async (projectId: string, itemId: string): Promise<void> => {
		return await this.client.delete(urls.loan.loanInterest.penaltyInterest.delete
											.replace(urlTemplateParts.id, projectId)
											.replace(urlTemplateParts.secondId, itemId));
	};
	
	getAccruedInterest = async (projectId: string, parameters: ApiGetAccruedInterestParameters): Promise<ApiAccruedInterestItems> => {
		let data = await this.client.post<{ items: object[], totalCount: number }>(urls.loan.loanInterest.accruedInterest.get.replace(
			urlTemplateParts.id, projectId), parameters);
		return plainToInstance(ApiAccruedInterestItems, data);
	};
	
	addAccruedInterestItems = async (parameters: ApiAccruedInterestAddItemsParameters): Promise<ApiAccruedInterest[]> => {
		const data = await this.client.post<object[]>(urls.loan.loanInterest.accruedInterest.add, parameters);
		return plainToInstance(ApiAccruedInterest, data);
	};
	
	updateAccruedInterest = async (id: string, item: ApiAccruedInterest): Promise<void> => {
		return await this.client.put(urls.loan.loanInterest.accruedInterest.update.replace(urlTemplateParts.id, id), item);
	};
	
	massUpdateAccruedInterest = async (parameters: ApiMassUpdateAccruedInterest): Promise<void> => {
		return await this.client.post(urls.loan.loanInterest.accruedInterest.massUpdate, parameters);
	};
	
	submitExtraRateAccruedInterest = async (payload: ApiExtraRateParameters): Promise<void> => {
		return await this.client.post(urls.loan.loanInterest.accruedInterest.extraRate, payload);
	};
	
	deleteAccruedInterestItem = async (itemId: string): Promise<void> => {
		return await this.client.delete(urls.loan.loanInterest.accruedInterest.delete.replace(urlTemplateParts.id, itemId));
	};
	
	getUpcomingPayments = async (projectId: string): Promise<ApiUpcomingPaymentsItem[]> => {
		const { items } = await this.client.get(urls.loan.loanInterest.upcomingPayments.getUpcomingPayments.replace(urlTemplateParts.id,
			projectId));
		return plainToInstance(ApiUpcomingPaymentsItem, items);
	};
	
	getUpcomingPaymentsInterestDetails = async (projectId: string): Promise<ApiUpcomingPaymentsInterestDetailsItem[]> => {
		const { items } = await this.client.get(urls.loan.loanInterest.upcomingPayments.getUpcomingPaymentsInterestDetails.replace(
			urlTemplateParts.id,
			projectId));
		return plainToInstance(ApiUpcomingPaymentsInterestDetailsItem, items);
	};
	
	getPaymentOrderFile = async (projectId: string, sourceId: string, paymentOrderType: string): Promise<Blob> => {
		return this.client.get<Blob>(prepareUrl(urls.loan.loanInterest.upcomingPayments.getPaymentOrderFile.replace(urlTemplateParts.id,
				projectId).replace(urlTemplateParts.secondId, sourceId).replace(urlTemplateParts.thirdId, paymentOrderType)),
			{ responseType: "blob" });
	};
}
